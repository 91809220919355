import { ShoppingResult } from "../../WizefindChatClient";

interface ProductResultsGridProps {
    results: ShoppingResult[];
}

export const ProductResultsGrid = ({ results }: ProductResultsGridProps) => {
    return (
        <div className="relative w-full mt-4 max-h-[400px] max-w-6xl overflow-hidden">
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 gap-4 snap-x snap-mandatory">
                {results.map((result) => (
                    <div 
                        key={result.position} 
                        className="flex-none w-[300px] snap-start border rounded-lg p-3 shadow-sm flex flex-col hover:shadow-md transition-shadow relative"
                    >
                        <div className="flex-1">
                            <img src={result.thumbnail} alt={result.title} className="w-full h-48 object-contain mb-1" />
                            <h3 className="font-semibold text-xs line-clamp-2">{result.title}</h3>
                            <p className="text-base font-bold text-blue-600">{result.price}</p>
                            <div className="flex items-center gap-1 text-xs text-gray-600">
                                <span className="truncate">{result.seller}</span>
                                {result.rating && (
                                    <span>• {result.rating}★</span>
                                )}
                            </div>
                        </div>
                        <a
                            href={result.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="absolute bottom-2 right-2 text-xs bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                        >
                            Details
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}; 