import { FormEvent, useState } from 'react';

interface MainChatInputProps {
    value?: string;
    onChange: (value: string) => void;
    onSubmit: (message: string) => void;
    placeholder?: string;
    buttonText?: string;
}

export const MainChatInput = ({
    value,
    onChange,
    onSubmit,
    placeholder = "Type your message...",
    buttonText = "Send"
}: MainChatInputProps) => {
    const [localValue, setLocalValue] = useState('');

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const messageToSend = value ?? localValue;
        if (messageToSend.trim()) {
            onSubmit(messageToSend);
            setLocalValue('');
        }
    };

    const handleChange = (newValue: string) => {
        setLocalValue(newValue);
        onChange(newValue);
    };

    return (
        <form onSubmit={handleSubmit} className="relative max-w-3xl mx-auto m-2">
            <input
                type="text"
                value={value ?? localValue}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                className="w-full bg-gray-100 text-gray-900 placeholder-gray-500 rounded-lg p-4 focus:outline-none focus:ring-2 focus:ring-blue-200"
            />
            <button
                type="submit"
                className="absolute right-3 top-1/2 -translate-y-1/2 bg-blue-200 px-6 py-2 rounded-lg font-medium"
            >
                {buttonText}
            </button>
        </form>
    );
}; 