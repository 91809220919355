import { ParsedMessage } from "../types/chat";

export const parseMessage = (content: string): ParsedMessage => {
    const questionBlocks = content.match(/<QUESTION>[\s\S]*?(?=<QUESTION>|$)/g) || [];
    
    const questions: string[] = [];
    const possibleAnswersByQuestion: string[][] = [];

    questionBlocks.forEach(block => {
        const question = block.match(/<QUESTION>([\s\S]*?)<\/QUESTION>/)?.[1]?.trim();
        if (question) {
            questions.push(question);
            
            const answers = block.match(/<PA>([\s\S]*?)<\/PA>/g)?.map(a =>
                a.replace(/<\/?PA>/g, '').trim()
            ) || [];
            possibleAnswersByQuestion.push(answers);
        }
    });

    const recommendations = content.match(/<RECOMMENDATION>([\s\S]*?)<\/RECOMMENDATION>/g)?.map(r =>
        r.replace(/<\/?RECOMMENDATION>/g, '').trim()
    ) || [];

    const prices = content.match(/<PRICE>([\s\S]*?)<\/PRICE>/g)?.map(p =>
        p.replace(/<\/?PRICE>/g, '').trim()
    ) || [];

    const descriptions = content.match(/<DESCRIPTION>([\s\S]*?)<\/DESCRIPTION>/g)?.map(d =>
        d.replace(/<\/?DESCRIPTION>/g, '').trim()
    ) || [];

    const introText = content
        .split(/<QUESTION>|<RECOMMENDATION>/)[0]
        .replace(/<TABLE>[\s\S]*?<\/TABLE>/g, '')
        .trim();

    const table = content.match(/<TABLE>([\s\S]*?)<\/TABLE>/g)?.map(tableContent => {
        return tableContent
            .replace(/<\/?TABLE>/g, '')
            .trim();
    })[0] || null;

    return {
        introText,
        questions,
        possibleAnswersByQuestion,
        recommendations,
        prices,
        descriptions,
        table
    };
};

