import { SearchResultVideo } from '../../WizefindChatClient';
import { Brain as BrainIcon } from 'lucide-react';

interface VideoResultsGridProps {
    videos: SearchResultVideo[];
}

export const VideoResultsGrid: React.FC<VideoResultsGridProps> = ({ videos }) => {
    return (
        <div className="relative w-full mt-4 max-h-[400px] max-w-6xl overflow-hidden">
            <div className="flex overflow-x-auto overflow-y-hidden pb-4 gap-4 snap-x snap-mandatory ">
                {videos.map((video) => (
                    <div 
                        key={video.id} 
                        className="flex-none w-[300px] snap-start flex flex-col border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow relative"
                    >
                        <a href={video.link} target="_blank" rel="noopener noreferrer">
                            <img 
                                src={video.thumbnail?.static || ''} 
                                alt={video.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-3">
                                <h3 className="font-medium text-sm line-clamp-2 mb-1">
                                    {video.title}
                                </h3>
                                <div className="flex items-center text-xs text-gray-600 space-x-2">
                                    {video.length && (
                                        <span>{video.length}</span>
                                    )}
                                    {video.views && (
                                        <span>• {new Intl.NumberFormat().format(video.views)} views</span>
                                    )}
                                </div>
                                {video.channel?.name && (
                                    <div className="text-xs text-gray-600 mt-1">
                                        {video.channel.name}
                                    </div>
                                )}
                            </div>
                        </a>
                        <button
                            className="absolute bottom-2 right-2 text-xs bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                        >
                            {/* <BrainIcon size={12} /> */}
                            Summary
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};