import React from 'react';
import { ParsedMessage, SavedAnswer } from '../../types/chat';
import { Recommendations } from './Recommendations';
import { RecommendationsTable } from './RecommendationsTable';
import { QuestionBlock } from './QuestionBlock';

interface MessageContentProps {
    parsedMessage: ParsedMessage;
    onSendMessage?: (message: string) => void;
    onSendAnswers?: (answers: SavedAnswer[]) => void;
    answers: SavedAnswer[];
    setAnswers: React.Dispatch<React.SetStateAction<SavedAnswer[]>>;
}

export const MessageContent = ({ parsedMessage, onSendMessage, onSendAnswers, answers, setAnswers }: MessageContentProps) => {
    const { introText, questions, possibleAnswersByQuestion, recommendations, prices, descriptions, table } = parsedMessage;

    return (
        <div className="space-y-4">
            {introText && (
                <p className="text-[15px] leading-relaxed">{introText}</p>
            )}

            {table && (
                <RecommendationsTable table={table} />
            )}

            {recommendations.length > 0 && (
                <Recommendations
                    recommendations={recommendations}
                    prices={prices}
                    descriptions={descriptions}
                    onSendMessage={onSendMessage}
                />
            )}

            {questions.length > 0 && questions.map((question, index) => (
                <QuestionBlock
                    key={index}
                    question={question}
                    questionIndex={index}
                    possibleAnswers={possibleAnswersByQuestion[index]}
                    answers={answers}
                    setAnswers={setAnswers}
                />
            ))}

            {answers.length > 0 && onSendAnswers && (
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            onSendAnswers(answers);
                            setAnswers([]);
                        }}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Send Answers
                    </button>
                </div>
            )}
        </div>
    );
}; 