import { useState } from 'react';
import { PRODUCT_CATEGORIES } from '../../constants';
import { hashMessage } from '../../pages/ChatPage';
import { ChatMessage as ChatMessageType, SavedAnswer } from '../../types/chat';
import { parseMessage } from '../../utils/parseMessage';
import { MessageContent } from './MessageContent';
import { QuickReplyButtons } from './QuickReplyButtons';
import { ChatInput } from './ChatInput';
import { ProductResultsGrid } from './ProductResultsGrid';
import { SearchResultVideo, ShoppingResult } from '../../WizefindChatClient';
import { VideoResultsGrid } from './VideoResultsGrid';

interface ChatMessageProps {
    message: ChatMessageType;
    isLast: boolean;
    onSendAnswers?: (answers: SavedAnswer[]) => void;
    onSendMessage?: (message: string) => void;
    isFirstReply?: boolean;
    currentResults: Record<string, ShoppingResult[]>;
    currentVideos: Record<string, SearchResultVideo[]>;
}

export const ChatMessage = ({ message, isLast, onSendAnswers, onSendMessage, isFirstReply, currentResults, currentVideos }: ChatMessageProps) => {
    const isUser = message.role === 'user';
    const [answers, setAnswers] = useState<SavedAnswer[]>([]);

    const parsedMessage = parseMessage(message.content);

    return (
        <div className={`flex items-start gap-4 ${isUser ? 'justify-end' : 'justify-start'}`}>
            {!isUser && (
                <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    🌎
                </div>
            )}
            <div>
                <div className="flex flex-row">
                    {isUser ? (
                        <div className={`max-w-[80%] rounded-lg p-3 ml-auto ${isUser ? 'bg-blue-200' : 'bg-gray-200'}`}>
                            <p className="text-[15px] leading-relaxed font-normal">
                                {message.content}
                            </p>
                        </div>
                    ) : (
                        <div className={`max-w-[80%] rounded-lg p-3 ${isUser ? 'bg-blue-200' : 'bg-gray-200'}`}>
                            <MessageContent
                                parsedMessage={parsedMessage}
                                onSendMessage={onSendMessage}
                                onSendAnswers={onSendAnswers}
                                answers={answers}
                                setAnswers={setAnswers}
                            />
                        </div>
                    )}
                </div>
                {currentResults[hashMessage(message.content)] && message.role === 'assistant' && (
                    <ProductResultsGrid results={currentResults[hashMessage(message.content)]} />
                )}
                {currentVideos[hashMessage(message.content)] && message.role === 'assistant' && (
                    <VideoResultsGrid videos={currentVideos[hashMessage(message.content)]} />
                )}

                <div>
                    {isFirstReply && (
                        <div className="mt-4">
                            <div className="flex flex-wrap gap-2">
                                {PRODUCT_CATEGORIES.map((category) => (
                                    <button
                                        key={category}
                                        onClick={() => onSendMessage?.(`I am looking for ${category}`)}
                                        className="px-4 py-2 rounded-full text-sm bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white transition-colors"
                                    >
                                        {category}
                                    </button>
                                ))}

                            </div>
                            <ChatInput 
                                onSubmit={(value) => onSendMessage?.(`I am looking for ${value}`)}
                                placeholder="Custom category or product..."
                                className="mt-4"
                            />
                        </div>
                    )}
                </div>
                {!isFirstReply && isLast && (
                    <div className="flex flex-col gap-2 my-4">
                        <QuickReplyButtons 
                            onSendMessage={onSendMessage}
                            hasRecommendations={parsedMessage.recommendations.length > 0}
                        />
                        <ChatInput 
                            onSubmit={(value) => onSendMessage?.(`I am looking for ${value}`)}
                            placeholder="I am looking for..."
                            className="mt-4"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

