interface QuickReplyButtonsProps {
    onSendMessage?: (message: string) => void;
    hasRecommendations: boolean;
}

export const QuickReplyButtons = ({ onSendMessage, hasRecommendations }: QuickReplyButtonsProps) => {
    return (
        <div className="flex flex-wrap gap-2">
            {hasRecommendations && (
                <button
                    onClick={() => onSendMessage?.("These products are not what I am looking for. Help me find the right ones.")}
                    className="px-4 py-2 rounded-full text-sm bg-blue-500 text-white hover:bg-blue-600"
                >
                    Not what I am looking for
                </button>
            )}
            <button
                onClick={() => onSendMessage?.("Just show me some products")}
                className="px-4 py-2 rounded-full text-sm bg-blue-500 text-white hover:bg-blue-600"
            >
                Just show me some products
            </button>
            <button
                onClick={() => onSendMessage?.("What do I have to look out for?")}
                className="px-4 py-2 rounded-full text-sm bg-blue-500 text-white hover:bg-blue-600"
            >
                What to look out for?
            </button>
        </div>
    );
}; 