import { API_URL, Product } from "./WizefindClient";

export interface GoogleShoppingSearchResult {
    position?: number | null;
    product_id?: string | null;
    product_link?: string | null;
    title?: string | null;
    seller?: string | null;
    offers?: string | null;
    offers_link?: string | null;
    price?: string | null;
    extracted_price?: number | null;
    rating?: number | null;
    reviews?: number | null;
    thumbnail?: string | null;
}

export interface ShoppingResult {
    position: number;
    title: string;
    price: string;
    extracted_price: number;
    rating?: number;
    reviews?: number;
    seller: string;
    thumbnail: string;
    link: string;
}


export interface ProductInfo {
    search_metadata: Record<string, any>;
    search_parameters: Record<string, any>;
    search_information: Record<string, any>;
    filters: any[];
    shopping_results: ShoppingResult[];
    featured_results?: any[];
    categories?: any[];
    pagination?: Record<string, any>;
    shopping_ads?: any[];
}

export interface SearchResultVideo {
    position: number;
    id: string;
    title: string;
    link: string;
    description?: string;
    views?: number;
    channel?: {
        [key: string]: any;
    };
    length?: string;
    published_time?: string;
    thumbnail?: {
        static?: string;
        rich?: string;
    };
    transcript?: string;
}

export interface ChatRequest {
    chat_id?: string;
    message_history: string[];
}

export interface ChatResponse {
    chat_id: string;
    messages: string[];
}

export const chat = async (chatRequest: ChatRequest): Promise<ChatResponse> => {
    return await fetch(`${API_URL}/api/chat`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(chatRequest),
    }).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error("Error chatting:", error);
        throw error;
    });
}

export const getProductInfo = async (productName: string): Promise<ProductInfo> => {
    return await fetch(`${API_URL}/api/product-info?product_name=${encodeURIComponent(productName)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error("Error getting product info:", error);
        throw error;
    });
}

export interface GetYoutubeVideosOnlyRequest {
    search_query: string;
}

export const getYoutubeVideos = async (request: GetYoutubeVideosOnlyRequest): Promise<SearchResultVideo[]> => {
    return await fetch(`${API_URL}/api/get-youtube-videos-only`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    }).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error("Error getting youtube videos:", error);
        throw error;
    });
}
