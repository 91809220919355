import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface RecommendationsTableProps {
    table: string;
}

export const RecommendationsTable = ({ table }: RecommendationsTableProps) => {
    return (
        <div className="overflow-x-auto my-4">
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    table: ({ node, ...props }) => (
                        <table className="min-w-full table-auto border-collapse shadow-md bg-white rounded-lg overflow-hidden my-4" {...props} />
                    ),
                    thead: ({ node, ...props }) => (
                        <thead className="bg-white border-b border-gray-100" {...props} />
                    ),
                    tr: ({ node, ...props }) => (
                        <tr className="hover:bg-gray-50 transition-colors" {...props} />
                    ),
                    th: ({ node, ...props }) => (
                        <th className="border-b border-gray-100 px-6 py-3 bg-white text-left text-xs font-semibold text-gray-500 uppercase tracking-wider" {...props} />
                    ),
                    td: ({ node, ...props }) => (
                        <td className="border-b border-gray-50 px-6 py-4 text-sm text-gray-600 whitespace-normal" {...props} />
                    ),
                    ul: ({ node, ...props }) => (
                        <ul className="list-disc list-inside space-y-1 my-2 ml-2 text-gray-600" {...props} />
                    ),
                    h1: ({ node, ...props }) => (
                        <h1 className="text-2xl font-bold text-gray-800 my-4" {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                        <h2 className="text-xl font-semibold text-gray-800 my-3" {...props} />
                    ),
                    h3: ({ node, ...props }) => (
                        <h3 className="text-lg font-medium text-gray-700 my-2" {...props} />
                    )
                }}
            >
                {table}
            </ReactMarkdown>
        </div>
    );
};