export const API_URL = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:8000";

export interface Product {
    name: string;
    description: string | null;
    is_software: boolean;
    google_shopping_data: GoogleShoppingSearchResult | null;
    mentioned_in_youtube_videos: SearchResultVideo[] | null;
    youtube_reviews: YoutubeProductReview[] | null;
    youtube_reviews_summary: SummaryOfSummaries | null;
}

export interface GoogleShoppingSearchResult {
    position?: number | null;
    product_id?: string | null;
    product_link?: string | null;
    title?: string | null;
    seller?: string | null;
    offers?: string | null;
    offers_link?: string | null;
    price?: string | null;
    extracted_price?: number | null;
    rating?: number | null;
    reviews?: number | null;
    thumbnail?: string | null;
}

export interface YoutubeProductReview {
    video: SearchResultVideo
    summary: string
    pros: string | null
    cons: string | null
    recommendations: string | null
    quotes: string | null
}

export interface SummaryOfSummaries {
    pros: string
    cons: string
    quotes: string
}

export interface SearchResultVideo {
    position: number;
    id: string;
    title: string;
    link: string;
    description?: string;
    views?: number;
    channel?: {
        [key: string]: any;
    };
    length?: string;
    published_time?: string;
    thumbnail?: {
        static?: string;
        rich?: string;
    };
    transcript?: string;
}

export interface ChatRequest {
    chat_id?: string;
    message_history: string[];
}

export interface ChatResponse {
    chat_id: string;
    messages: string[];
}

export class CancellationError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "CancellationError";
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

const checkForCancellation = async <T>(response: Response): Promise<T> => {
    if (response.headers.get('X-Request-Cancelled') === 'true') {
        throw new CancellationError();
    }
    return response.json();
}

export const extractProducts = async (userQuery: string, requestId: string): Promise<Product[]> => {
    return await fetch(`${API_URL}/api/extract-products`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_query: userQuery, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<Product[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error extracting products:", error);
        throw error;
    });
}

export const getYoutubeVideos = async (product: Product, requestId: string): Promise<SearchResultVideo[]> => {
    return await fetch(`${API_URL}/api/get-youtube-videos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ product: product, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<SearchResultVideo[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error getting youtube videos:", error);
        throw error;
    });
}

export const reviewProducts = async (products: Product[], videos: SearchResultVideo[], requestId: string): Promise<Product[]> => {
    return await fetch(`${API_URL}/api/review-products`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ products: products, videos: videos, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<Product[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error reviewing products:", error);
        throw error;
    });
}

export const generateProductComparisonTable = async (reviewedProducts: Product[], requestId: string): Promise<string> => {
    return await fetch(`${API_URL}/api/generate-product-comparison-table`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reviewed_products: reviewedProducts, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<string>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error generating product comparison table:", error);
        throw error;
    });
}

export const generateSummaryOfSummaries = async (reviewedProducts: Product[], requestId: string): Promise<Product[]> => {
    return await fetch(`${API_URL}/api/generate-summary-of-summaries`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reviewed_products: reviewedProducts, request_id: requestId }),
    }).then(async (response) => { // todo: Claude recommended to use async here. But other functions don't use async. Why?
        return checkForCancellation<Product[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error generating summary of summaries:", error);
        throw error;
    });
}

export const generateConclusion = async (reviewedProducts: Product[], comparisonTable: string, requestId: string): Promise<string> => {
    return await fetch(`${API_URL}/api/generate-conclusion`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reviewed_products: reviewedProducts, comparison_table: comparisonTable, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<string>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error generating conclusion:", error);
        throw error;
    });
}

export const discoverProductVideos = async (userQuery: string, requestId: string): Promise<SearchResultVideo[]> => {
    return await fetch(`${API_URL}/api/discover-product-videos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_query: userQuery, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<SearchResultVideo[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error discovering product videos:", error);
        throw error;
    });
}

export const discoverProducts = async (userQuery: string, videos: SearchResultVideo[], requestId: string): Promise<Product[]> => {
    return await fetch(`${API_URL}/api/discover-products`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_query: userQuery, videos: videos, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<Product[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error discovering products:", error);
        throw error;
    });
}

// TODO: fix model
export const getExportedState = async (exportId: string): Promise<any> => {
    return await fetch(`${API_URL}/api/get-exported-state`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ export_id: exportId }),
    }).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error("Error getting exported state:", error);
        throw error;
    });
}

export const exportState = async (state: any): Promise<any> => {
    return await fetch(`${API_URL}/api/export-state`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ state: state }),
    }).then((response) => {
        return response.json();
    }).catch((error) => {
        console.error("Error exporting state:", error);
        throw error;
    });
}

export const getVideoTranscripts = async (videos: SearchResultVideo[], requestId: string): Promise<SearchResultVideo[]> => {
    return await fetch(`${API_URL}/api/get-transcripts-for-videos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videos: videos, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<SearchResultVideo[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error getting transcripts for videos:", error);
        throw error;
    });
}

export const searchVideos = async (userQuery: string, requestId: string): Promise<SearchResultVideo[]> => {
    return await fetch(`${API_URL}/api/search-videos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_query: userQuery, request_id: requestId }),
    }).then((response) => {
        return checkForCancellation<SearchResultVideo[]>(response);
    }).catch((error) => {
        if (error instanceof CancellationError) {
            throw error;
        }
        console.error("Error searching videos:", error);
        throw error;
    });
}


export const testAllCalls = async () => {
    // COMPARE pipeline
    const userQuery = 'Dropbox vs Google Drive';
    const requestId = '123';
    const products: Product[] = await extractProducts(userQuery, requestId);
    console.log(products);

    const allVideos: SearchResultVideo[] = [];
    for (const product of products) {
        const videos = await getYoutubeVideos(product, requestId);
        console.log(videos);
        allVideos.push(...videos);
    }
    console.log(allVideos);

    const reviewedProducts: Product[] = await reviewProducts(products, allVideos, requestId);
    console.log(reviewedProducts);

    const productComparisonTable: string = await generateProductComparisonTable(reviewedProducts, requestId);
    console.log(productComparisonTable);

    const conclusion: string = await generateConclusion(reviewedProducts, productComparisonTable, requestId);
    console.log(conclusion);
}
