import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ChatMessage } from '../components/chat/ChatMessage';
import { ChatMessage as ChatMessageType, SavedAnswer } from '../types/chat';
import { chat, getProductInfo, ProductInfo, ShoppingResult } from '../WizefindChatClient';
import { MainChatInput } from '../components/chat/MainChatInput';
import { Spinner } from '../components/chat/Spinner';
import { getYoutubeVideos, SearchResultVideo } from '../WizefindChatClient';

const INITIAL_MESSAGE: ChatMessageType = {
    id: '1',
    role: 'assistant',
    content: 'Hello there! What are you looking for?'
};

export const hashMessage = (message: string): string => {
    // Simple but effective way to create a shorter hash
    let hash = 0;
    for (let i = 0; i < message.length; i++) {
        const char = message.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash).toString(36); // Convert to base36 for shorter string
};

export const ChatPage = () => {
    const [messages, setMessages] = useState<ChatMessageType[]>([INITIAL_MESSAGE]);
    const [inputMessage, setInputMessage] = useState('');
    const [chatId, setChatId] = useState<string>(uuidv4());
    const [shoppingResults, setShoppingResults] = useState<Record<string, ShoppingResult[]>>({});
    const [youtubeVideos, setYoutubeVideos] = useState<Record<string, SearchResultVideo[]>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleMessage = async (content: string | SavedAnswer[]) => {
        // Convert answers array to string if needed
        const messageContent = Array.isArray(content)
            ? content.map(a => `${a.question}: ${a.answer}`).join('\n')
            : content;

        if (!messageContent.trim()) return;

        // Add user message to chat
        setMessages(prev => [...prev, {
            id: Date.now().toString(),
            role: 'user',
            content: messageContent
        }]);

        setIsLoading(true);
        try {
            const response = await chat({
                chat_id: chatId,
                message_history: [...messages.map((message) => message.content), messageContent]
            });

            setChatId(response.chat_id);
            setMessages(prev => [...prev, {
                id: Date.now().toString(),
                role: 'assistant',
                content: response.messages[response.messages.length - 1]
            }]);
            if (messageContent.includes('Tell me more')) {
                const productName = messageContent.replace('Tell me more about ', '').trim();
                const messageHash = hashMessage(response.messages[response.messages.length - 1]);

                await getProductInfo(productName)
                    .then((productInfo: ProductInfo) => {
                        console.log('productInfo', productInfo);
                        if (productInfo?.shopping_results?.length > 0) {
                            setShoppingResults(prev => ({
                                ...prev,
                                [messageHash]: productInfo.shopping_results.slice(0, 6)
                            }));
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching product info:', error);
                    });

                await getYoutubeVideos({search_query: productName})
                    .then((videos: SearchResultVideo[]) => {
                        setYoutubeVideos(prev => ({
                            ...prev,
                            [messageHash]: videos
                        }));
                    })
                    .catch((error) => {
                        console.error('Error fetching youtube videos:', error);
                    });
            }
        } catch (error) {
            console.error('Chat error:', error);
        } finally {
            setIsLoading(false);
            setInputMessage('');
        }
    };

    const isFirstReply = (message: ChatMessageType, index: number, messages: ChatMessageType[]) => {
        if (message.role !== 'assistant') return false;
        const previousAssistantMessages = messages
            .slice(0, index)
            .filter(m => m.role === 'assistant');
        return previousAssistantMessages.length === 0;
    };

    const isLastReply = (message: ChatMessageType, index: number, messages: ChatMessageType[]) => {
        if (message.role !== 'assistant') return false;
        const subsequentAssistantMessages = messages
            .slice(index + 1)
            .filter(m => m.role === 'assistant');
        return subsequentAssistantMessages.length === 0;
    };

    return (
        <div className='mx-auto max-w-6xl h-screen flex flex-col'>
            <nav className="border-b px-6 py-3 flex items-center relative">
                <div className="w-full flex flex-row gap-4">
                    <a href="/" className="mr-auto">
                        <span className="text-base sm:text-3xl font-semibold">
                            wizefind
                        </span>
                    </a>
                </div>
            </nav>
            <div className='my-4'></div>

            <div className="flex-1 flex flex-col bg-white">
                <div className="flex-1 overflow-y-auto px-4 container mx-auto space-y-4 pb-24">
                    {messages.map((message, index) => (
                        <ChatMessage
                            key={message.id}
                            message={message}
                            isLast={isLastReply(message, index, messages)}
                            onSendAnswers={handleMessage}
                            onSendMessage={handleMessage}
                            isFirstReply={isFirstReply(message, index, messages)}
                            currentResults={shoppingResults}
                            currentVideos={youtubeVideos}
                        />
                    ))}
                    {isLoading && (
                        <div className="flex justify-center py-4">
                            <Spinner className="w-6 h-6 text-gray-400" />
                        </div>
                    )}
                </div>
                

                <div className="sticky bottom-0 bg-white">
                    <MainChatInput
                        value={inputMessage}
                        onChange={setInputMessage}
                        onSubmit={handleMessage}
                        placeholder="Type your message..."
                        buttonText="Send"
                    />
                </div>
            </div>

        </div>
    );
};

export default ChatPage;