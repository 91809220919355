
interface RecommendationsProps {
    recommendations: string[];
    prices: string[];
    descriptions: string[];
    onSendMessage?: (message: string) => void;
}

export const Recommendations = ({ recommendations, prices, descriptions, onSendMessage }: RecommendationsProps) => {
    const formatDescription = (description: string | undefined) => {
        if (!description) return '';
        return description
            .replace(/- Key specs:/g, '- <span class="text-purple-600 font-bold">Key specs:</span>')
            .replace(/- Pros:/g, '- <span class="text-green-600 font-bold">Pros:</span>')
            .replace(/- Cons:/g, '- <span class="text-red-600 font-bold">Cons:</span>');
    };

    const chunks = recommendations.reduce((acc: number[][], _, i) => {
        const chunkIndex = Math.floor(i / 3);
        if (!acc[chunkIndex]) {
            acc[chunkIndex] = [];
        }
        acc[chunkIndex].push(i);
        return acc;
    }, []);

    return (
        <div className="space-y-4">
            {chunks.map((chunk, chunkIndex) => (
                <div key={chunkIndex} className="flex space-x-4">
                    {chunk.map((index) => (
                        <div
                            key={index}
                            className="flex-1 bg-white rounded-lg shadow-md p-4 border border-gray-200 flex flex-col"
                        >
                            <div className="flex-1">
                                <h3 className="text-lg font-semibold mb-2">{recommendations[index]}</h3>
                                <p className="text-blue-600 font-bold mb-2">{prices[index]}</p>
                                <p
                                    className="text-sm text-gray-700 whitespace-pre-wrap"
                                    dangerouslySetInnerHTML={{
                                        __html: formatDescription(descriptions[index])
                                    }}
                                />
                            </div>
                            <button
                                onClick={() => onSendMessage?.(`Tell me more about ${recommendations[index]}`)}
                                className="w-full mt-4 px-4 py-2 rounded-full text-sm bg-orange-300 text-gray-700 hover:bg-blue-500 hover:text-white transition-colors"
                            >
                                Details and Prices
                            </button>
                            <button
                                onClick={() => onSendMessage?.(`I like this product ${recommendations[index]}. Can you show me similar or comparable products?`)}
                                className="w-full mt-2 px-4 py-2 rounded-full text-sm bg-gray-300 text-gray-700 hover:bg-blue-500 hover:text-white transition-colors"
                            >
                                Similar Products
                            </button>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}; 