import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NextUIProvider } from "@nextui-org/system";
import MainPage from './MainPage';
import CategoryDisplayPage from './pages/CategoryDisplayPage';
import DiscoveryPage from './pages/DiscoveryPage';
import ChatPage from './pages/ChatPage';

export const CATEGORY_DISPLAY_PAGE_PATH = "/category"
export const DISCOVERY_PAGE_PATH = "/discovery"
export const CHAT_PAGE_PATH = "/chat"

const App = () => {
  return (
    <NextUIProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path={CATEGORY_DISPLAY_PAGE_PATH} element={<CategoryDisplayPage />} />
            <Route path={DISCOVERY_PAGE_PATH} element={<DiscoveryPage />} />
            <Route path={CHAT_PAGE_PATH} element={<ChatPage />} />
          </Routes>
        </div>
      </Router>
    </NextUIProvider>
  );
};

export default App;