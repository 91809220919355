export const PRODUCT_CATEGORIES = [
    "Tennis Racquets",
    "Badminton Racquets",
    "Squash Racquets",
    "Pickleball Paddles",
    "Table Tennis Paddles",
    "Racquetball Racquets",
    "Smartphones",
    "Laptops",
    "Headphones",
    "Smart Watches",
    "Tablets",
    "Skincare Products",
    "Hair Care Products",
    "Beauty Tools",
    "Bicycles",
    "E-Bikes",
    "Scooters",
    "Coffee Makers",
    "Blenders",
    "Air Fryers",
    "Kitchen Knives",
    "Food Processors"
] as const;
